// Out of house
import React, { useContext, useMemo, useState, memo, useCallback } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";

// In house
import DashList from "./dashboardList";
import Logo from "../../common/Logo/logo";
import SearchAutoFill from "../../common/searchAutoFillNavBar";
import { GlobalContext } from "../../../store/globalContext";
// import useWindowDimensions from '../../common/windowDimensions';

const drawerWidth = 240;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: "#192734",
  [theme.breakpoints.up("md")]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
}));

const StyledMenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));

const StyledDrawer = styled("nav")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

const StyledLogo = styled(Grid)(({ theme }) => ({
  width: 40,
  marginRight: 5,
}));


const Dashboard = ({ allAssetNames, window }) => {
  // const {width} = useWindowDimensions()
  const [mobileOpen, setMobileOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  let navigate = useNavigate();

  const { user, snackBarMsg, snackBarSeverity, handleCloseSnackbar } =
    useContext(GlobalContext);
  const snackBarTimeDuration = useMemo(
    () => (snackBarMsg ? (snackBarMsg.length > 80 ? 6000 : 3000) : 3000),
    [snackBarMsg]
  );

  const clickSearchIcon = () => setSearchOpen(!searchOpen);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const drawer = useMemo(() => {
    return (
      <>
        <StyledToolbar>
          {user.name ? (
            <h5 className="conatiner m-0 pt-3 text-center align-items-center text-secondary">
              👋{"  "}
              {user.name.substring(0, 15)}
            </h5>
          ) : (
            ""
          )}
        </StyledToolbar>

        <Divider />
        <DashList user={user} />
      </>
    );
  }, [user]);

  const handleSubmit = useCallback(
    (ticker) => {
      let search = ticker.toUpperCase();
      if (String(search).includes(".X"))
        search = String(search).replace(".X", ".x");
      if (allAssetNames.includes(search))
        return navigate(`/asset/${search}`);
      return console.log("Input not in list");
    },
    [allAssetNames, navigate]
  );

  const renderTopRight = useMemo(() => {
    return (
      <>
        <Grid className="mx-0">
          {
            <Typography noWrap>
              {user.name
                ? ""
                : !searchOpen && (
                    <NavLink to="/Sign In" style={{ textDecoration: "none" }}>
                      <Button
                        variant="contained"
                        className="bg-dark mr-2"
                        style={{ color: "#ff5a3f", borderRadius: 5 }}
                      >
                        {/* Access */}
                        <span className="material-icons">&#xe0da;</span>
                      </Button>
                    </NavLink>
                  )}
            </Typography>
          }
        </Grid>
      </>
    );
  }, [searchOpen, user.name]);

  return (
    <div>
      <CssBaseline />
      <Snackbar
        open={snackBarMsg !== ""}
        autoHideDuration={snackBarTimeDuration}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {snackBarMsg && (
          <Alert onClose={handleCloseSnackbar} severity={snackBarSeverity}>
            {snackBarMsg}
          </Alert>
        )}
      </Snackbar>
      <StyledAppBar position="fixed">
        <Toolbar>
          <>
            <StyledMenuButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </StyledMenuButton>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <StyledLogo item>
                <NavLink to="/dash">
                  <Logo />
                </NavLink>
              </StyledLogo>

              {!searchOpen && (
                <Grid item>
                  <Typography variant="h6" noWrap>
                    <NavLink
                      className="h7 nav-item nav-link text-light p-0 ml-1"
                      to="/dash"
                    >
                      Trend Edge
                    </NavLink>
                  </Typography>
                </Grid>
              )}
              <Box p={1} bgcolor="#192734" className="ml-auto p-0">
                <div style={{ textAlign: "center" }}>{renderTopRight}</div>
              </Box>
            </Grid>
          </>
          <Box
            display="flex"
            justifyContent="flex-end"
            m={0}
            p={0}
            bgcolor="#192734"
            alignItems="center"
            style={{ border: "none" }}
          >
            <SearchAutoFill
              handleSubmit={handleSubmit}
              searchList={allAssetNames}
              // className={classes.search}
              searchOpen={searchOpen}
              clickSearchIcon={clickSearchIcon}
            />
          </Box>
        </Toolbar>
      </StyledAppBar>

      <StyledDrawer>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Box
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                background: "#192734",
                zIndex: "auto",
              },
            }}
          >
            {drawer}
          </Drawer>

          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                background: "#192734",
                zIndex: "auto",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </StyledDrawer>
    </div>
  );
};

Dashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default memo(Dashboard);
