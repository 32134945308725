// Out of house
import React, { memo, useState, lazy, Suspense} from "react";
import List from "@mui/material/List";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import { NavLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { Icon } from "@iconify/react";
import GetAppIcon from "@mui/icons-material/GetApp";
import { darken } from "polished";
import BasicModal from "../../common/basicModal";

// In House
import ItemLink from "../../common/itemLink";

const WatchlistManager = lazy(() => import("../watchlist/watchlistManager"));

const navLinkCheckuser = (user, name, path, icon) => {
  let visibleClass = "nav-item nav-link p-0 m-0 text-";
  user.name ? (visibleClass += "light") : (visibleClass += "secondary");
  return (
    <NavLink
      className={visibleClass}
      to={`/${path}`}
      onClick={(e) => {
        if (!user.name) e.preventDefault();
      }}
    >
      <ItemLink name={name}>{icon}</ItemLink>
    </NavLink>
  );
};

const navLinkNormal = (name, path, color = "light", icon) => {
  return (
    <NavLink
      className={"nav-item align-items-center nav-link p-0 m-0 text-" + color}
      to={`/${path}`}
      style={color === "install" ? { color: "#ff5a3f" } : { color: "red" }}
    >
      <ItemLink name={name} style={{ color: "none" }}>
        {icon}
      </ItemLink>
    </NavLink>
  );
};

const DashList = ({ user }) => {
  const [openMountWListModal, setOpenMountWListModal] = useState(false);

  const {watchlists, currentWatchlist} = user

  return (
    <List className="mb-5">
      {navLinkNormal(
          "Screener",
          "screener",
          "light",
          <span className="material-icons text-light align-items-center">
            <Icon
              style={{ verticalAlign: "top" }}
              icon="ic:baseline-query-stats"
            />
          </span>
        )}

      {navLinkNormal(
        "Indicators",
        "indicators",
        "light",
        <span className="material-icons text-light align-items-center">
          <Icon style={{ verticalAlign: "top" }} icon="mdi:sign-routes" />
        </span>
      )}

      {navLinkNormal(
        "Weekly Insights",
        "weeklyInsights",
        "light",
        <span className="material-icons text-light">
          <Icon
            style={{ verticalAlign: "top" }}
            icon="mdi:calendar-refresh"
          />
        </span>
      )}

      <div
        style={{
          border: "1px solid #9e9e9e",
          borderRadius: "5px",
        }}
      >
        {navLinkCheckuser(
          user,
          "Watchlist",
          "Watchlist",
          <span
            className={
              user.name
                ? "material-icons text-light"
                : "material-icons text-secondary"
            }
          >
            &#xe0ee;
          </span>
        )}
        <div style={{ display: "flex", alignItems: "center",  padding: "0 5px 5px",}}>
          <Icon
            style={{
              verticalAlign: "top",
              color: "#fff",
              marginRight: "1%",
              fontSize: "24px",
            }}
            icon="ic:baseline-swap-horiz"
          />
          <BasicModal
            handleOpen={setOpenMountWListModal}
            handleClose={setOpenMountWListModal}
            open={openMountWListModal}
            buttonSize={"medium"}
            buttonClass={"w-100 p-1 m-0 ml-1"}
            fullWidth={true}
            titleClass='px-0'
            sx={{
              background: "#1d2124",
              border: "1px solid #9e9e9e",
              "&:hover": {
                  background: darken(0.1, "#1d2124"),
                      borderColor: "#E34824",
              },
              borderRadius: 1,
            }}
            buttonTitle={
              !user.name ? 
              <NavLink
                to="/Sign In"
                className="nav-item nav-link p-0 m-0 text-light"
              >
                Sign In to Manage Watchlists
              </NavLink> :
              currentWatchlist
            }
            title={"Watchlist Manager"}
            disabled={!user.name}
            showStuff={openMountWListModal && 
            <Suspense fallback={''}>
                <WatchlistManager
                    setOpenMountWListModal={setOpenMountWListModal}
                    watchlists={watchlists}
                    currentWatchlist={currentWatchlist}
                />
              </Suspense>
            }
          />
        </div>
      </div>
      {navLinkNormal(
        "Institutions",
        "institutions",
        "light",
        <span
          className="material-icons text-light align-items-center ml-1"
          style={{ fontSize: "120%" }}
        >
          <Icon style={{ verticalAlign: "top" }} icon="fa:institution" />
        </span>
      )}
      {navLinkNormal(
        "Sector & Industry Share Statistics",
        "industryStats",
        "light",
        <span className="material-icons text-light">
          <Icon
            style={{ verticalAlign: "top" }}
            icon="carbon:chart-histogram"
          />
        </span>
      )}

      {navLinkCheckuser(
        user,
        "Settings",
        "settings",
        <SettingsIcon className={user.name ? "text-light" : "text-secondary"} />
      )}

      {user &&
        user.isAdmin &&
        navLinkNormal(
          "Admin Dash",
          "Admin",
          "light",
          <span className="material-icons text-light">&#xef3d;</span>
        )}

      <Divider
        className="my-2"
        sx={{ borderBottomWidth: 2, bgcolor: "#9e9e9e" }}
      />
      {user.name
        ? navLinkNormal(
            "Sign Out",
            "Sign Out",
            "danger",
            <span className="material-icons text-danger">&#xe9BA;</span>
          )
        : navLinkNormal(
            "Sign In",
            "Sign In",
            "success",
            <span className="material-icons text-success">&#xea77;</span>
          )}

      {navLinkNormal(
        "About",
        "about",
        "info",
        <InfoIcon className="text-info" />
      )}
      {/* {navLinkNormal('Sponsor', 'sponsor', 'success', <span style={{color:'white'}}>🙏</span>)} */}

      {navLinkNormal(
        "Install",
        "install",
        "install",
        <GetAppIcon style={{ color: "#ff5a3f" }} />
      )}
      {navLinkNormal(
        "Release Notes",
        "releaseNotes",
        "warning",
        <span className="material-icons text-warning">&#xeb81;</span>
      )}
    </List>
  );
};

export default memo(DashList);
