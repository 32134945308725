import jwtDecode from 'jwt-decode'
import http from './httpService'

const apiEndpoint = '/auth'
const tokenKey = "token"

export const getJwt = () => localStorage.getItem(tokenKey)

http.setJwt(getJwt())

export const login = async (email, password, recovery='') => {
    const {data: jwt} = await http.post(apiEndpoint, {email, password, recovery})
    localStorage.setItem(tokenKey, jwt);
}

export const loginGoogle = async (tok, showWelcomeMessage) => {
    // console.log(jwtDecode(tok))
    const {data:jwt} = await http.post(apiEndpoint+'/goog', {tok, showWelcomeMessage})
    localStorage.setItem(tokenKey, jwt);
}

export const loginWithJwt = jwt => localStorage.setItem(tokenKey, jwt);

export const logout = () => localStorage.removeItem(tokenKey);

export const getCurrentUser = () => {
    try {
        const jwt = localStorage.getItem(tokenKey);
        return jwtDecode(jwt)
      } catch (error) {
          return null;
      }
}

const authexport = {
    login,
    loginWithJwt,
    logout,
    getCurrentUser,
    getJwt,
    loginGoogle
}

export default authexport